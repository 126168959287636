.searchWrapper{
	width: 91%;
	margin: 20px auto 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid #E6A84C;
	border-radius: 7px;
	padding: 10px 20px;
	font-size: 30px;
	.searchInp{
		background-color: transparent; 
		width: 98%;
		border: none;
		font-size: 18px;
		&:focus {
			outline: none;
		}
	}
	.searchIcon {
		display: inline-flex;
		color: black;
	}
}


