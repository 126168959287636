
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
body{
	font-family: 'Inter', sans-serif;
}
.container{
	background: #f4f1e9 url("../src/assets/images/bgImg.png") repeat fixed;  
	max-width: 800px;
	margin: 0 auto;
	padding: 40px 0;
}

.categoryName{
	display: inline-block;
	padding: 1rem .5rem;
	white-space: nowrap;
	font-weight: 600;
	font-size: 12px;
	color: #171717;
	border-radius: 8px;
	border: 1px solid #E6A84C;
	display: flex;
	justify-content: center;
	align-items: center;
   }
.activeCategory {
 text-align: start;	
  background-color: #E6A74C;
  }

/* chakra header(lang) selected css */
/* .css-iohxn1{
	left: 55%;
}
.css-1k5t13e {
	padding: 0px 0px 1px 9px !important;
} */

