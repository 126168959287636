#category{
	.categoryTitleWrapper{
		width: 95%;
		margin: 0 0 0 auto;
			.customClassname {
				width: auto;
			}
	
		}
.line{
	display: block;
	margin: 25px auto;
	width: 91%;
	height: 0.03125rem;
	background-color: #171717;
	opacity: 0.3;
	border: none;
}
.WaySort{
width: 90%;
margin: 0 auto;
display: flex;
justify-content: space-between;
align-items: center;
.siteWay{
	width: 91%;
	margin: 0 auto;
	display: flex;
	align-items: center;
.homePage{
	font-size: .75rem;
	font-weight: 500;
	color: #171717;
	opacity: 0.5;
}
.arrowIcon{
	margin: 2px 4px 0 7px;
}
.currentPage{
	font-size: .75rem;
	font-weight: 500;
	color: #171717;
	opacity: 0.7;
}
}
}
.currentPageTitle{
	width: 91%;
    margin: 20px auto;
	color: #E6A74C;
	font-family: 'IBM Plex Serif', serif;
	font-size: 1.25rem;
	font-weight: 700;
}
.prList{
	width: 91%;
    margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
	justify-content: center;
	gap: 2rem;
}
}


