.PrCartWrapper{
	width: 100%;
	display: flex;
	justify-content: space-between;
	img{
		width: 50%;
		border-radius: 1.25rem;
		object-fit: cover;
		aspect-ratio: 1/1;
	}
	.prDescription{
		width: 50%;
		margin-left: 7px;
     .PrTitle{
		color: #171717;
		font-family: 'IBM Plex Serif', serif;
		font-size: 1.375rem;
		line-height: 1.5rem;
		font-weight: 700;
	 }
	 .prComposition{
		margin: 15px 0;
		color:#171717;
		font-size: 0.875rem;
		font-style: italic;
		font-weight: 400;
	 }
	 .prPriceWeight{
		display: flex;   
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		.Weight{
			color:  #171717;
			font-family: 'IBM Plex Serif', serif;
			font-size: 1rem;
			font-weight: 700;
		}
		.line{
			flex: 1;
			margin: 3px 7px 0;
			height: 1px;
			border: none;
			background-color: #171717;
			opacity: 0.3;
		}
		.price{
			color: rgba(230, 168, 76, 1);
			white-space: nowrap;
			font-family: 'IBM Plex Serif', serif;
			font-size: 18px;
			font-weight: 700;
			font-family: initial;
		}
	 }
	}
}