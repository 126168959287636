#CategoryNameAndImage{
	.CategoryWrapper{
		width: 96%;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
		justify-items: center;
		row-gap: 2rem;
		margin: 0 auto;
		.category{
			width: 100%;
			height: 90px;
			display: flex;
			justify-content: center;   
			align-items: center;
			border-radius: 20px;
			position: relative;
			.image{
				width: 90%;
				height: 100%;
				position: relative;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 20px;
			}
			.overlay{
				position: absolute;
				top: 0;
				left: 0;
				background-color: rgba(0, 0, 0, 0.4);
				width: 100%;
				height: 100%;
				border-radius: 20px;
			}
		}
			.categoryName{
				font-size: 22px;
				font-weight: 700;
				font-family: 'IBM Plex Serif', serif;
				position: absolute;
				top: 50%;
				white-space: nowrap;
				left: 50%;
				transform: translate(-50%,-50%);
				color: white;
			}
		}
	}
}