#footer{
	max-width: 800px;
	margin:0 auto;
	padding: 15px 0;
	background-color: #E6A84C;
		.footerLogo{
			display: block;
			margin: 0 auto;
			width: 115px;
			height: 28px;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		           }
		.looptech{
		margin-top: 15px;
		display: flex;
		justify-content: center;
		align-items: center;
			.prepared{
				// font-size: 20px;
			    font-weight: 500;
				margin-right: 10px;
				font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
			}
			.looptechLogo{
				width: 90px;
				pointer-events: none
			}
		}
	}