// .sort{
// 	border: none;
// 	background: none;
// 	padding: .5rem 7px;
// 	outline: none;
// 	width: 135px;
// 	border-radius: 8px;  
// 	cursor: pointer;
// 	font-weight: 600;
//     font-size: 12px;
//     color: #171717;
//     border-radius: 8px;
//     border: 1px solid #E6A84C;
// }

.sort{
	border: 1px solid #E6A74C!important;
	border-radius: 8px;  
	font-weight: 600;
    font-size: 12px !important;
	color: black;
	padding-left: 8px !important;
	background-color: #E6A74C!important;
	cursor: pointer;
	user-select: none;
	outline: none;
	option{
		color: black;
	}
}