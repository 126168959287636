#searchResult{
	.categoryTitleWrapper{
		width: 95%;
		margin: 0 0 0 auto;
			.customClassname {
				width: auto;
			.categoryName{
				display: inline-block;
				padding: 1rem .5rem;
				white-space: nowrap;
				font-weight: 500;
				font-size: 12px;
				color: #171717;
				border-radius: 8px;
				border: 1px solid #E6A84C;
				display: flex;
				justify-content: center;
				align-items: center;}
			}
		
		}

	.siteWay{
		width: 91%;
		margin: 0 auto;
		padding-bottom: 30px;
		display: flex;
		align-items: center;
	.homePage{
		font-size: .75rem;
		font-weight: 500;
		color: #171717;
		opacity: 0.5;
	}
	.arrowIcon{
		margin: 2px 4px 0 7px;      
	}
	.currentPage{
		font-size: .75rem;
		font-weight: 500;
		color: #171717;
		opacity: 0.9;
	}
	}
	.line{
		display: block;
		margin: 25px auto;
		width: 91%;
		height: 0.03125rem;
		background-color: #171717;
		opacity: 0.3;
		border: none;
	}
  .searchResultWrapper{
	width: 91%;
	margin: 0 auto;

	.searchResult{
	width: 100%;
    margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
	justify-content: center;
	gap: 2rem;
	}
	.noResultFound{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.searchValue{
			margin-top: 20px;
		}
	}
	.loading{
		margin-top: 14px;
		text-align: center;
	}
  }	
}




